import { inject } from 'tsyringe'
import { clientOnly } from '~/decorators'
import { containerScoped } from '~/decorators/dependency-container'
import { inBrowser } from '~/utils/env'
import LoggerService from '~/services/LoggerService'
import { VueI18n, VueRouter } from '~/utils/nuxt3-migration'
import SnackbarService from '~/services/snackbar/SnackbarService'
import RequestBuilder from '~/builders/http/RequestBuilder'
import { LoginResult } from '~/models/login-register/types'

import { USER_NS } from '~/store/modules/shared/user/state'
import { StoreWithRootState } from '~/store/types'
import {
  configToken,
  storeToken,
  vueI18nToken,
  vueRouterToken
} from '~/constants/dependency-injection/tokens'
import { toCamelCase } from '~/utils/object'

@containerScoped()
export default class FacebookLoginService {
  private scriptLoaded = false
  private loader: any = null

  constructor(
    @inject(configToken) private config: any,
    @inject(LoggerService) private logger: LoggerService,
    @inject(vueI18nToken) private i18n: VueI18n,
    @inject(vueRouterToken) private router: VueRouter,
    @inject(SnackbarService) private snackbar: SnackbarService,
    @inject(RequestBuilder) private requestBuilder: RequestBuilder,
    @inject(storeToken) private store: StoreWithRootState
  ) {}

  @clientOnly
  public async getFB(): Promise<any> {
    if (!this.scriptLoaded) {
      if (!this.loader) {
        this.initScript()
      }
      await this.loader
    }

    return inBrowser() ? window.FB : {}
  }

  @clientOnly
  initScript() {
    const loader = () => {
      return new Promise<void>((resolve, reject) => {
        const sdkScript = document.createElement('script')
        sdkScript.id = 'facebook-jssdk'
        sdkScript.setAttribute(
          'src',
          'https://connect.facebook.net/en_US/sdk.js'
        )
        window.fbAsyncInit = async () => {
          this.scriptLoaded = true
          await this.FBInit()
          await this.LogPageView()
          resolve()
        }
        sdkScript.onerror = err => {
          reject(err)
        }
        document.head.appendChild(sdkScript)
      })
    }
    this.loader = loader().catch(e => {
      this.logger.captureError(e)
    })
  }

  @clientOnly
  async FBInit(): Promise<void> {
    const {
      public: { facebookAppId }
    } = this.config

    try {
      const FB = await this.getFB()
      if (FB) {
        await FB.init({
          appId: facebookAppId,
          autoLogAppEvents: true,
          xfbml: true,
          version: 'v2.7'
        })
      }
    } catch (error) {}
  }

  @clientOnly
  async LogPageView(): Promise<void> {
    try {
      const FB = await this.getFB()
      if (FB) {
        await FB.AppEvents.logPageView()
      }
    } catch (error) {
      return this.logger.captureError(error)
    }
  }

  @clientOnly
  public async FBLogin(_callback: Function): Promise<void> {
    try {
      const FB = await this.getFB()
      if (FB) {
        const a = document.createElement('button')
        document.body.appendChild(a)
        a.onclick = await FB.login(
          async (response: any) => {
            if (
              response.status === 'connected' &&
              response.authResponse?.grantedScopes?.includes('email')
            ) {
              try {
                const resp = await this.backendLogin(
                  response.authResponse.accessToken,
                  response.authResponse.userID
                )
                _callback && _callback(resp)
              } catch (error) {
                _callback && _callback(null)
              }
              return
            } else if (
              response.authResponse &&
              !response.authResponse.grantedScopes.includes('email')
            ) {
              this.snackbar.error(
                this.i18n.t('all permissions are mandatory!') as string
              )
            }
            _callback && _callback(null)
          },
          {
            scope: 'public_profile,email',
            return_scopes: true,
            auth_type: 'rerequest'
          }
        )
        a.click()
        document.body.removeChild(a)
      }
    } catch (error) {
      _callback && _callback(null)
    }
  }

  @clientOnly
  private async backendLogin(
    accessToken: string,
    userId: string
  ): Promise<LoginResult | null> {
    try {
      let redirectTo
      const currentQuery = this.router?.currentRoute?.query
      if (currentQuery?.gotonext || currentQuery?.redirectto) {
        redirectTo = currentQuery?.gotonext || currentQuery?.redirectto
      }

      let refcode
      if (this.store.getters[`${USER_NS}/isSingleOrAnon`]) {
        refcode = this.store.getters[`${USER_NS}/referenceCode`]
      } else if (this.router?.currentRoute?.query?.refcode) {
        refcode = this.router.currentRoute.query.refcode
      }

      return await this.requestBuilder
        .request('post', '/api/login/facebook/')
        .data({
          access_token: accessToken,
          user_id: userId,
          redirect_to: redirectTo,
          refcode
        })
        .send()
    } catch (error) {
      if (error.response?.data?.data) {
        const { relatedToDealers } = toCamelCase(
          (error as any).response.data.data
        )
        if (relatedToDealers?.length && process.client) {
          window.location.href = this.router.resolve({
            name: '__login',
            query: {
              gotonext: this.router.currentRoute.path,
              username:
                relatedToDealers.length === 1
                  ? relatedToDealers[0].username
                  : undefined,
              relatedDealer: '1'
            }
          }).href
        }
        return null
      }
      this.snackbar.error(
        this.i18n.t('an error occurred please try again later') as string
      )
      this.logger.captureError(error)
      return null
    }
  }
}
