import { CIcon } from '~/icons/types'

export const ciApple: CIcon = {
  width: '21',
  height: '20',
  paths: [
    {
      d:
        'M18.165 14.027a9.653 9.653 0 0 1-1.022 1.74c-.537.726-.976 1.228-1.315 1.507-.525.458-1.088.692-1.69.706-.434 0-.955-.117-1.563-.354-.609-.235-1.169-.352-1.681-.352-.537 0-1.113.117-1.729.352-.617.237-1.114.36-1.494.372-.578.024-1.154-.217-1.729-.724-.367-.303-.826-.823-1.376-1.56-.59-.787-1.075-1.699-1.455-2.739-.407-1.123-.611-2.21-.611-3.264 0-1.206.275-2.247.826-3.118a4.723 4.723 0 0 1 1.73-1.659 4.848 4.848 0 0 1 2.339-.625c.459 0 1.061.134 1.809.399.746.265 1.225.4 1.435.4.157 0 .689-.158 1.591-.471.853-.291 1.573-.412 2.163-.364 1.598.122 2.799.72 3.597 1.795-1.43.82-2.136 1.97-2.122 3.444.013 1.149.452 2.104 1.316 2.863.392.352.83.625 1.316.818-.105.29-.217.568-.335.835ZM14.499.36c0 .9-.347 1.74-1.038 2.519-.835.925-1.845 1.46-2.94 1.375a2.649 2.649 0 0 1-.022-.341c0-.865.397-1.79 1.102-2.546a4.23 4.23 0 0 1 1.342-.955C13.486.162 13.998.024 14.48 0c.014.12.02.24.02.36Z'
    }
  ],
  ellipses: [],
  circles: [],
  name: 'apple',
  type: 'brand'
}
