














import FacebookLoginService from '~/services/login/FacebookLoginService'
import { LoginResult } from '~/models/login-register/types'
import { computed, defineComponent, onMounted } from '~/utils/nuxt3-migration'
import { useDep } from '~/compositions/dependency-container'
import { useI18n } from '~/compositions/i18n'
import { ciFacebook } from '~/icons/source/brand/facebook'
import { useCookies } from '~/compositions/useCookies'

export default defineComponent({
  props: {
    loading: {
      type: Boolean,
      required: true
    }
  },
  setup(_props, { emit }) {
    const facebookLoginService = useDep(FacebookLoginService)
    const { t } = useI18n()
    const cookies = useCookies()

    onMounted(() => facebookLoginService.getFB())
    const title = computed(() => `${t('continue with')} Facebook`)

    function afterLogin(response: LoginResult | null) {
      cookies.clearSessionCookies()
      if (response?.redirectUrl) {
        window.location = response.redirectUrl
      }
      if (response === null) {
        emit('set-loading', false)
      }
    }

    function login() {
      emit('click')
      emit('set-loading', true)
      facebookLoginService.FBLogin(afterLogin)
    }

    return { facebookLoginService, login, title, ciFacebook }
  }
})
