














import { ciGoogle } from '~/icons/source/brand/google'
import { mapDeps } from '~/plugins/dependency-container/utils'
import GoogleLoginService, {
  GOOGLE_SIGNIN_EVENT_ID
} from '~/services/login/GoogleLoginService'
import qs from 'qs'
import { v4 as uuidv4 } from 'uuid'
import { defineComponent } from '~/utils/nuxt3-migration'
import { toCamelCase } from '~/utils/object'
import CookiesService from '~/services/CookiesService'

export default defineComponent({
  props: {
    loading: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      popupInterval: '',
      stateTokenId: '',
      loggingIn: false
    }
  },
  computed: {
    ...mapDeps({
      googleLoginService: GoogleLoginService,
      cookiesService: CookiesService
    }),
    title() {
      return `${this.$t('continue with')} Google`
    },
    googleIcon() {
      return ciGoogle
    }
  },
  beforeDestroy() {
    this.googleLoginService.removeMessageListener(this.callback)
    clearInterval(this.popupInterval)
  },
  methods: {
    login() {
      this.$emit('click')
      this.$emit('set-loading', true)
      this.googleLoginService.addMessageListener(this.callback)
      const randomToken = uuidv4()
      this.googleLoginService.openPopup(randomToken)

      this.popupInterval = setInterval(this.checkIfPopupClosed, 300)
    },
    checkIfPopupClosed() {
      const popup = this.googleLoginService.getAuthWindow()
      if (popup && popup.closed) {
        clearInterval(this.popupInterval)
        if (!this.loggingIn) {
          this.$emit('set-loading', false)
        }
      }
    },
    async callback(message: MessageEvent) {
      if (message?.data?.event_id === GOOGLE_SIGNIN_EVENT_ID) {
        const params = message.data.data
        const { code, state } = qs.parse(params, {
          ignoreQueryPrefix: true
        })
        if (this.googleLoginService.validateStateCookie(state)) {
          this.loggingIn = true
          try {
            const { redirectUrl } = await this.googleLoginService.backendLogin(
              code
            )
            this.googleLoginService.removeMessageListener(this.callback)

            this.cookiesService.clearSessionCookies()
            window.location = redirectUrl || '/'
          } catch (error) {
            if (error.response?.data?.data) {
              const { relatedToDealers } = toCamelCase(
                (error as any).response.data.data
              )
              if (relatedToDealers?.length && process.client) {
                window.location.href = this.$router.resolve({
                  name: '__login',
                  query: {
                    gotonext: this.$route.path,
                    username:
                      relatedToDealers.length === 1
                        ? relatedToDealers[0].username
                        : undefined,
                    relatedDealer: 1
                  }
                }).href
              }
            }
            this.loggingIn = false
            this.$logger.captureError(error)
            this.$snackbar.error(
              this.$t('an error occurred please try again later')
            )
            this.$emit('set-loading', false)
          }
        }
      }
    }
  }
})
