import { CIcon } from '~/icons/types'

export const ciFacebook: CIcon = {
  width: 21,
  height: 20,
  paths: [
    {
      d:
        'M10.5 0C5 0 .4 4.5.4 10.1c0 5 3.6 9.1 8.4 9.9h.1v-7.1H6.4v-2.8h2.5V7.8c0-2.5 1.6-3.9 3.9-3.9.7 0 1.5.1 2.2.2v2.6h-1.3c-1.2 0-1.5.6-1.5 1.4v2h2.7l-.5 2.8h-2.2V20h-.1c4.8-.9 8.4-5 8.4-9.9C20.6 4.5 16 0 10.5 0z'
    }
  ],
  ellipses: [],
  circles: [],
  name: 'facebook',
  type: 'brand'
}
