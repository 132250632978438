import { render, staticRenderFns } from "./FacebookLoginButton.vue?vue&type=template&id=35d381ce&scoped=true&"
import script from "./FacebookLoginButton.vue?vue&type=script&lang=ts&"
export * from "./FacebookLoginButton.vue?vue&type=script&lang=ts&"
import style0 from "./FacebookLoginButton.vue?vue&type=style&index=0&id=35d381ce&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35d381ce",
  null
  
)

export default component.exports