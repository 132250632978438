import { inject } from 'tsyringe'
import { clientOnly } from '~/decorators'
import { containerScoped } from '~/decorators/dependency-container'

import LoggerService from '~/services/LoggerService'
import { httpToken } from '~/constants/dependency-injection/tokens'
import { AxiosInstance } from 'axios'
import RequestBuilder from '~/builders/http/RequestBuilder'
import { v4 as uuidv4 } from 'uuid'
import { toCamelCase } from '~/utils/object'

@containerScoped()
export default class AppleLoginService {
  private scriptLoaded = false
  private loader: any = null
  private state = ''

  constructor(
    @inject(LoggerService) private logger: LoggerService,
    @inject(httpToken) private http: AxiosInstance,
    @inject(RequestBuilder) private requestBuilder: RequestBuilder
  ) {
    this.state = uuidv4()
  }

  @clientOnly
  initScript() {
    const loader = () => {
      return new Promise<void>((resolve, reject) => {
        const sdkScript = document.createElement('script')
        sdkScript.id = 'appleid'
        sdkScript.setAttribute(
          'src',
          'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js'
        )
        sdkScript.onerror = err => reject(err)
        document.head.appendChild(sdkScript)
        sdkScript.onload = () => {
          window.AppleID.auth.init({
            clientId: 'com.webcar.gr',
            scope: 'email name',
            redirectURI: `${window.location.origin}/apple_signin`,
            state: this.state,
            usePopup: true,
            grant_type: 'authorization_code'
          })

          this.scriptLoaded = true

          resolve()
        }
      })
    }
    this.loader = loader()
  }

  public getAppleID() {
    if (this.scriptLoaded) {
      return this.loader
    } else {
      return this.initScript()
    }
  }

  public async login(callback: Function = () => {}): Promise<void> {
    try {
      const data = await window.AppleID.auth.signIn()
      const res = await this.requestBuilder
        .request('post', '/api/login/apple/')
        .data(data.authorization)
        .send()

      callback(toCamelCase(res))
    } catch (error) {
      this.logger.captureError(error as Error)
    }
  }
}
